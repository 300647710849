import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx";
import CodeBlock from "./code-block";
import tw from "twin.macro";


const Anchor = tw.a`text-green-600 font-bold hover:bg-green-600 hover:text-white hover:rounded`

// Define overrides for how MDX is converted to React components
const components = {
  pre: CodeBlock,
  a: Anchor
}


const MdxBlock = (props) => {
  return <MDXProvider components={components}>
      <MDXRenderer>{props.children}</MDXRenderer>
    </MDXProvider>
}
export default MdxBlock;