import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import MdxBlock from "../components/mdx-block"
import tw from "twin.macro"
import { PostDate, PostTags } from "../components/post-preview"
import {Disqus} from "gatsby-plugin-disqus"
import moment from "moment"


// There are 4 columns remaining from the parent layout.js - can be used on the right side of the page if needed
const BlogPostTitle = tw.h1`mb-2 leading-9`
const BlogPostMeta = tw.div`mb-8`
const BlogPostGridWrapper = tw.div`grid grid-cols-12`
const BlogPostBodyColumn = tw.div`col-span-12 lg:col-span-9 xl:col-span-7`
const BlogPostCurrentlyReadingColumn = tw.div`lg:col-span-3 xl:col-span-5 py-12`
// const BlogPostCurrentlyReadingCard = tw.div`p-4 ml-12 sticky top-5 bg-gray-100 border border-gray-300 rounded`


export default function BlogPost(props) {
  let title = props.data.mdx.frontmatter.title
  console.log(title)
  let disqusConfig = {
    id: `disqus${title}`,
    url: `${props.location.pathname}`,
    title,
  }
  return (
    <Layout>
      <BlogPostGridWrapper>
        <BlogPostBodyColumn>
          <BlogPostTitle>{props.data.mdx.frontmatter.title}</BlogPostTitle>
          <BlogPostMeta>
            <PostDate>{moment(props.data.mdx.frontmatter.date).format('MMMM D, YYYY')}</PostDate> <br />
            <PostTags>{props.data.mdx.frontmatter.tags.join(" | ")}</PostTags>
          </BlogPostMeta>
          <MdxBlock>{props.data.mdx.body}</MdxBlock>
        </BlogPostBodyColumn>
        <BlogPostCurrentlyReadingColumn>
          {/* <BlogPostCurrentlyReadingCard>Final column</BlogPostCurrentlyReadingCard> */}
        </BlogPostCurrentlyReadingColumn>
      </BlogPostGridWrapper>
      {/* <Disqus config={disqusConfig} /> */}
    </Layout>
  )
}


// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.
export const query = graphql`
  query GetBlogPost($slug: String) {
    mdx(slug: { eq: $slug }) {
        body
        frontmatter {
          title
          date
          tags
        }
    }
  }
  `