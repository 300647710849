import React from 'react'
import Highlight, {defaultProps} from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/github'
import tw from 'twin.macro'

const HighlightWrapper = tw.div`my-4`

const CodeBlock = (props) => {
    const className = props.children.props.className || ''
    const matches = className.match(/language-(?<lang>.*)/)
  return (
    <HighlightWrapper>
        <Highlight {...defaultProps} code={props.children.props.children.trim()} language={
            matches && matches.groups && matches.groups.lang
                ? matches.groups.lang
                : ''
            }
            theme={theme}>
            {({className, style, tokens, getLineProps, getTokenProps}) => (
            <pre className={className} style={{...style}}>
                {tokens.map((line, i) => (
                <div key={i} {...getLineProps({line, key: i})}>
                    {line.map((token, key) => (
                    <span key={key} {...getTokenProps({token, key})} />
                    ))}
                </div>
                ))}
            </pre>
            )}
        </Highlight>
    </HighlightWrapper>
  )
}

export default CodeBlock